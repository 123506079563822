import { Component, Inject, Input, PLATFORM_ID, TransferState } from '@angular/core';
import { ButtonType } from '../general-button/general-button.component';
import { isPlatformBrowser } from '@angular/common';
import { BlogPost } from '../../../interfaces/blog';
import { POSTS_VALUE } from '../../../../tokens';
import { BlogUrl } from '../../blog/blog.url';



@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrl: './blog.component.sass'
})
export class BlogComponent {
	buttonType = ButtonType;
	@Input() posts : BlogPost[] = [{
		postId: "1",
		category: {
			categoryId: "1"
		},
		postTitle: "Test " + 1,
		postMetadescription:  "Test",
		postUrl: "urltest",
		postImageUrl: "https://placehold.co/600x400"
	},{
		postId: "1",
		category: {
			categoryId: "1"
		},
		postTitle: "Test " + 1,
		postMetadescription:  "Test",
		postUrl: "urltest",
		postImageUrl: "https://placehold.co/600x400"
	},{
		postId: "1",
		category: {
			categoryId: "1"
		},
		postTitle: "Test " + 1,
		postMetadescription:  "Test",
		postUrl: "urltest",
		postImageUrl: "https://placehold.co/600x400"
	},{
		postId: "1",
		category: {
			categoryId: "1"
		},
		postTitle: "Test " + 1,
		postMetadescription:  "Test",
		postUrl: "urltest",
		postImageUrl: "https://placehold.co/600x400"
	},{
		postId: "1",
		category: {
			categoryId: "1"
		},
		postTitle: "Test " + 1,
		postMetadescription:  "Test",
		postUrl: "urltest",
		postImageUrl: "https://placehold.co/600x400"
	},{
		postId: "1",
		category: {
			categoryId: "1"
		},
		postTitle: "Test " + 1,
		postMetadescription:  "Test",
		postUrl: "urltest",
		postImageUrl: "https://placehold.co/600x400"
	}]
	@Input() numPosts: number = 0

	readonly blogUrl = BlogUrl;

	constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: Object) {
		if (isPlatformBrowser(this.platformId)){
			this.serializePosts()
		}
	}

	serializePosts() {
		const posts = this.transferState.get(POSTS_VALUE, []);
		const defaultPosts = this.posts.slice();

		this.posts = posts.map((post) => {
		  return {
			postId: post.post_id,
			category: post.category_id,
			postTitle: post.post_title,
			postMetadescription: post.post_metadescription,
			postUrl: post.post_url,
			postImageUrl: post.post_image_url
		  };
		});

		if (this.posts.length < 6) {
		  const remainingPosts = 6 - this.posts.length;
		  this.posts = this.posts.concat(defaultPosts.slice(0, remainingPosts));
		}
	  }
}
