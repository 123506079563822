<div
	class="montserrat w-full h-full hover:bg-[#FFFFFF66]"
	(click)="toggleDropdown()"
	[ngClass]="{
		'shadow-[rgba(0,71,81,1)_0px_0px_10px_1px] bg-[#FFFFFF66]':
			isDropdownOpen,
		'error-border': error,
		'green-border': !error,
		'locations-button': isLocationFilter,
		'categories-button': !isLocationFilter
	}"
	data-cy="dropdown-button"
>
	<label for="dropdown-input">
		<div class="flex flex-row w-full">
			<img
				src="{{ imageUrl }}"
				class="ps-[1.625rem]"
				[ngClass]="hasImage ? '' : 'hidden'"
			/>
			<div
				class="text-VerdeOscuro h-[3rem] flex justify-between items-center px-2 w-full dropdown-div"
				[ngClass]="hasAutocomplete ? '-z-10' : ''"
			>
				<input
					*ngIf="hasAutocomplete"
					class="text-start w-12/12 xs:button-4 sm:button-4 md:button-3 xl:button-2 bg-transparent outline-none placeholder:text-VerdeOscuro"
					id="dropdown-input"
					type="text"
					[ngClass]="{
						'xs:button-3 sm:button-3': isLocationFilter,
						'xs:button-4 sm:button-4 md:button-3':
							!isLocationFilter,
						'!font-extrabold':
							selectedRow?.title && !isLocationFilter,
						'!font-bold': !selectedRow?.title && !isLocationFilter
					}"
					(keyup)="updateList($event)"
					(click)="toggleDropdown()"
					[placeholder]="selectedRow?.title || title | translate"
				/>
				<span
					*ngIf="!hasAutocomplete"
					class="text-start w-12/12 xs:button-4 sm:button-4 md:button-3 xl:button-2 dropdown-text"
					[ngClass]="{
						'xs:button-3 sm:button-3': isLocationFilter,
						'xs:button-4 sm:button-4 md:button-3':
							!isLocationFilter,
						'!font-extrabold':
							selectedRow?.title && !isLocationFilter,
						'!font-bold': !selectedRow?.title && !isLocationFilter
					}"
					>{{ selectedRow?.title || title | translate }}</span
				>
				<img
					[ngClass]="hasChevron ? '' : 'hidden'"
					[src]="
						isDropdownOpen
							? 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flight%2Fchevrons%2Fchevron-up-fat.svg?alt=media'
							: 'https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flight%2Fchevrons%2Fchevron-down-fat.svg?alt=media'
					"
					(click)="toggleDropdown($event)"
				/>
			</div>
		</div>
	</label>
	<div class="relative">
		@if (isDropdownOpen) {
			<div
				class="dropdown border-VerdeOscuro absolute flex flex-col w-full backdrop-blur-[15px] bg-[#FFFFFF80] shadow-[rgba(0,71,81,1)_0px_0px_10px_1px]"
				[ngClass]="{
					'z-50': isLocationFilter,
					'h-[20rem]': isLocationFilter,
					'overflow-scroll': isLocationFilter
				}"
				data-cy="dropdown-div"
			>
				@for (d of data; track d) {
					<button (click)="selectOption(d)">
						<div
							class="flex justify-start text-left w-full items-center font-bold bg-transparent text-VerdeOscuro"
						>
							<span
								class="ml-[0.3125rem] w-full button-3 dropdown-option"
								>{{ d.title | translate }}</span
							>
						</div>
					</button>
				}
			</div>
		}
	</div>
</div>
