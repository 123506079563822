import { Component, HostListener, Input} from '@angular/core';
import { NavbarTheme } from './theme';
import { PlatformLanguage, AllLanguages } from './languages';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '../../../services/cookie-service.service';
import { ButtonType } from '../general-button/general-button.component';
import { LegalUrl } from '../../legal/legal.url';
import { CookiesUrl } from '../../cookies/cookies.url';
import { JobUrl } from '../../job/job.url';
import { TalentUrl } from '../../talent/talent.url';
import { HomeUrl } from '../../home/home.url';
import { Router,NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.sass'
})
export class NavbarComponent{

	@Input() theme: NavbarTheme = NavbarTheme.LIGHT;

	readonly homeUrl = HomeUrl;
	readonly jobUrl = JobUrl;
	readonly talentUrl = TalentUrl;

	buttonType = ButtonType;

	navbarTheme = NavbarTheme;
	opened: boolean = false;

	lightLogo = "https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FLogo_TWSLogo.svg?alt=media&token=5ac9ce01-f72e-4de4-925b-5e55525851ca"
	darkLogo = "https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FLogo_TWSlogo-dark.svg?alt=media&token=3d9496e5-5004-4eeb-a26c-f9f120d2259d"

	lightLogoLetters = "https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FLogo_TWSLogoLetra.svg?alt=media&token=60a86974-62bf-4331-b715-ddf954bcba30"
	darkLogoLetters = "https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2FLogo_TWSlogoLetra-dark.svg?alt=media&token=36c70495-931f-4a64-a173-641927ec0b4c"

	buttonTheme = this.buttonType.MAIN_VERDE;


	openMenuLight = "https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fmenu-light.svg?alt=media&token=1c119237-6df9-49fa-9b42-df3d73f158a7"
	openMenuDark = "https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fmenu-dark.svg?alt=media&token=7794f89d-88ee-4100-af49-3591fe89c0c2"
	closeMenuLight = "https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fclose-light.svg?alt=media&token=08eed7d2-7fa6-49bb-84c7-19ccb9c463ab"
	closeMenuDark = "https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fclose-dark.svg?alt=media&token=eb22fef1-50bf-4db0-9d09-36b3fbb2abb3"

	platformLanguages: PlatformLanguage[] = [];
	selectedLanguage: PlatformLanguage = AllLanguages[0]
	isDropdownOpen: boolean = false;
	logo: string = ""

	redirectURl = "https://talent.thewiseseeker.com/user/";

	constructor(
		public translate: TranslateService,
		private cookiesService: CookieService,
		private router : Router){

			router.events.subscribe((result)=>{
				if(result instanceof NavigationEnd){
					switch(result.url){
						case '/buscar-talento': {
							this.redirectURl= "https://talent.thewiseseeker.com/recruiter/";
							break;
						}
						default: {
							this.redirectURl= "https://talent.thewiseseeker.com/user/";
							break;
						}
					}
				}
			})
		}

	ngOnInit() {
		this.platformLanguages = AllLanguages.filter(lang => lang !== this.selectedLanguage)
		this.selectedLanguage = AllLanguages.filter(lang => lang.short.toLowerCase() == this.translate.currentLang)[0];
		this.cookiesService.setLanguageCookie(this.selectedLanguage.short);
		this.setLogo()

	}

	ngOnChanges(changes: any) {
		if (changes["theme"]){
			this.setLogo();
			if(changes["theme"].currentValue == NavbarTheme.LIGHT) this.buttonTheme = this.buttonType.MAIN_VERDE;
			if(changes["theme"].currentValue == NavbarTheme.DARK) this.buttonTheme = this.buttonType.MAIN_BLANCO;
		}

	}

	changeLanguage(lang: any) {
		this.selectedLanguage = lang
		this.setLanguages()
	}

	setLanguages() {
		this.platformLanguages = AllLanguages.filter(lang => lang !== this.selectedLanguage);
		let selectedLanguage = this.selectedLanguage ? this.selectedLanguage.short.toLowerCase() : 'es';
		this.translate.use(selectedLanguage);
		this.cookiesService.setLanguageCookie(selectedLanguage);
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.setLogo();
		if (window.innerWidth >= 1060)
			this.resetSideBar()
	}

	setLogo() {
		if (typeof window !== 'undefined') {
			const screenWidth = window.innerWidth;
			if (screenWidth >= 576 && screenWidth <= 1060) {
				this.logo = this.theme == this.navbarTheme.LIGHT ? this.darkLogoLetters : this.lightLogoLetters;
			} else {
				this.logo = this.theme == this.navbarTheme.LIGHT ? this.darkLogo : this.lightLogo;
			}
		}
	}

	resetSideBar() {
		const accessBar = document.getElementsByClassName("access-bar").item(0);
		const sidebarMenu = document.getElementsByClassName("sidebar-menu").item(0) as HTMLElement;
		if (accessBar && sidebarMenu) {
			accessBar.classList.remove('-translate-x-[11.5rem]');
			this.opened = false
			sidebarMenu.style.display = 'none'
		}
	}

	toggleSidebar = () => {
		const accessBar = document.getElementsByClassName("access-bar").item(0);
		const sidebarMenu = document.getElementsByClassName("sidebar-menu").item(0) as HTMLElement;
		if (accessBar && sidebarMenu) {
			this.opened = !this.opened;
			const display = this.opened ? 'block' : 'none';
			sidebarMenu.style.display = display
			accessBar.classList.toggle('-translate-x-[11.5rem]');
			accessBar.classList.toggle('access-bar-collapsed')
			accessBar.classList.toggle('access-bar-expanded')
		}
	}

	goToLink(url: string){
		window.open(url,"_self");
	}

	isHomeLinkActive() {
		// se debe activar el link del home tambien en las paginas de cookies y legal
		const baseUrl = this.router.url.split("?")[0].split("#")[0];
		return [`/${CookiesUrl.Base}`, `/${LegalUrl.Base}`].findIndex(a => a == baseUrl) != -1;
	}

}
