<div
	[ngClass]="this.isBlog ? 'shadow-xl' : ''"
	class="flex items-center overflow-hidden upper-section shadow-xl"
>
	<div
		class="flex items-center overflow-hidden"
		[ngClass]="{
			'bg-Verde':
				carrouselType == typeEnum.VERDE_VERDE ||
				carrouselType == typeEnum.VERDE_MORADO,
			'bg-MoradoClaro': carrouselType == typeEnum.MORADO_MORADO,
			'bg-VerdeNeon': carrouselType == typeEnum.NEON_VERDE
		}"
	>
		<h2
			class="animate-loop-scroll-left-slow flex items-center h2-slider montserrat header-4 sm:header-2 lg:header-1 text-VerdeOscuro"
			[ngClass]="{
				'text-section-jobs': !isEnterprise,
				'text-section-enterprise': isEnterprise,
				'bg-Verde':
					carrouselType == typeEnum.VERDE_VERDE ||
					carrouselType == typeEnum.VERDE_MORADO,
				'bg-MoradoClaro': carrouselType == typeEnum.MORADO_MORADO,
				'bg-VerdeNeon': carrouselType == typeEnum.NEON_VERDE
			}"
		>
			{{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }}&nbsp;
		</h2>

		<h2
			class="animate-loop-scroll-left-slow flex items-center h2-slider montserrat header-4 sm:header-2 lg:header-1 text-VerdeOscuro"
			[ngClass]="{
				'text-section-jobs': !isEnterprise,
				'text-section-enterprise': isEnterprise,
				'bg-Verde':
					carrouselType == typeEnum.VERDE_VERDE ||
					carrouselType == typeEnum.VERDE_MORADO,
				'bg-MoradoClaro': carrouselType == typeEnum.MORADO_MORADO,
				'bg-VerdeNeon': carrouselType == typeEnum.NEON_VERDE
			}"
			aria-hidden="true"
		>
			{{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }}&nbsp;
		</h2>
		<h2
			class="animate-loop-scroll-left-slow flex items-center h2-slider montserrat header-4 sm:header-2 lg:header-1 text-VerdeOscuro"
			[ngClass]="{
				'text-section-jobs': !isEnterprise,
				'text-section-enterprise': isEnterprise,
				'bg-Verde':
					carrouselType == typeEnum.VERDE_VERDE ||
					carrouselType == typeEnum.VERDE_MORADO,
				'bg-MoradoClaro': carrouselType == typeEnum.MORADO_MORADO,
				'bg-VerdeNeon': carrouselType == typeEnum.NEON_VERDE
			}"
			aria-hidden="true"
		>
			{{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }}
			{{ upper_text | translate }} {{ upper_text | translate }}&nbsp;
		</h2>
	</div>
</div>

@if (!isBlog) {
	<div
		class="flex items-center overflow-hidden"
		[ngClass]="{
			'bg-Verde':
				carrouselType == typeEnum.VERDE_VERDE ||
				carrouselType == typeEnum.NEON_VERDE,
			'bg-MoradoClaro':
				carrouselType == typeEnum.MORADO_MORADO ||
				carrouselType == typeEnum.VERDE_MORADO,
			'enterprise-section': isEnterprise,
			'text-section-jobs': !isEnterprise
		}"
	>
		@if (isEnterprise) {
			<div class="logo-slider animate-loop-scroll-right flex relative">
				@for (img of img_urls; track $index) {
					<img class="max-w-none" [src]="img" />
				}
			</div>
			<div
				class="logo-slider animate-loop-scroll-right flex relative"
				aria-hidden="true"
			>
				@for (img of img_urls; track $index) {
					<img class="max-w-none" [src]="img" />
				}
			</div>
			<div
				class="logo-slider animate-loop-scroll-right flex relative"
				aria-hidden="true"
			>
				@for (img of img_urls; track $index) {
					<img class="max-w-none" [src]="img" />
				}
			</div>
		} @else {
			<h2
				class="text-slider relative animate-loop-scroll-right-slow flex items-center h2-slider montserrat header-4 sm:header-2 lg:header-1 text-VerdeOscuro lower-section text-section-jobs"
				[ngClass]="{
					'bg-Verde':
						carrouselType == typeEnum.VERDE_VERDE ||
						carrouselType == typeEnum.NEON_VERDE,
					'bg-MoradoClaro':
						carrouselType == typeEnum.MORADO_MORADO ||
						carrouselType == typeEnum.VERDE_MORADO
				}"
			>
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}&nbsp;
			</h2>
			<h2
				class="text-slider relative animate-loop-scroll-right-slow flex items-center h2-slider montserrat header-4 sm:header-2 lg:header-1 text-VerdeOscuro lower-section text-section-jobs"
				[ngClass]="{
					'bg-Verde':
						carrouselType == typeEnum.VERDE_VERDE ||
						carrouselType == typeEnum.NEON_VERDE,
					'bg-MoradoClaro':
						carrouselType == typeEnum.MORADO_MORADO ||
						carrouselType == typeEnum.VERDE_MORADO
				}"
				aria-hidden="true"
			>
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}&nbsp;
			</h2>
			<h2
				class="text-slider relative animate-loop-scroll-right-slow flex items-center h2-slider montserrat header-4 sm:header-2 lg:header-1 text-VerdeOscuro lower-section text-section-jobs"
				[ngClass]="{
					'bg-Verde':
						carrouselType == typeEnum.VERDE_VERDE ||
						carrouselType == typeEnum.NEON_VERDE,
					'bg-MoradoClaro':
						carrouselType == typeEnum.MORADO_MORADO ||
						carrouselType == typeEnum.VERDE_MORADO
				}"
				aria-hidden="true"
			>
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}
				{{ randomizedText | translate }}&nbsp;
			</h2>
		}
	</div>
}
