<div
	class="overflow-hidden relative flex justify-center items-center h-[28rem] sm:h-[24.375rem] bg-gradient-to-b from-AzulOscuro to-VerdeOscuro"
>
	<!-- Logo TWS -->
	<img
		class="w-[18rem] md:w-[20.1875rem] pt-20 sm:pt-32"
		src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ffooter%2FLogo-TWS.png?alt=media"
		alt="Logo The Wise Seeker"
	/>

	<div
		class="absolute -bottom-11 sm:-bottom-16 md:-bottom-12 xl:-bottom-14 xxl:-bottom-36 -right-[3.2rem] sm:-right-[5.9rem] md:-right-[5rem] lg:-right-[4rem] xl:-right-[5.5rem] xxl:-right-28 w-[8.5rem] sm:w-[13.375rem] md:w-[12rem] xl:w-[14rem] xxl:w-[17rem]"
	>
		<!-- Seeky brazo -->
		<img class="-rotate-[15deg] w-full"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Fseeky%2Fcuerpo-seeky-sin-estrellas.svg?alt=media"
			alt="Logo The Wise Seeker"
		/>
	</div>
	<div
		class="absolute -bottom-14 lg:-bottom-20 left-0 lg:left-4 xl:left-10 w-[6.875rem] md:w-[8.8125rem] lg:w-[9.5625rem] xxl:w-[10.5rem]"
	>
		<!-- Wisy cuerpo entero -->
		<img
			class="rotate-12 transform -scale-x-100 w-full"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Fwisy%2Fwisy-cuerpo-entero.svg?alt=media"
			alt="Logo The Wise Seeker"
		/>
	</div>
	<div
		class="absolute top-[1.8rem] sm:top-[0.4375rem] md:-top-3 -left-[0.4031rem] sm:-left-[3rem] md:-left-[2rem] lg:left-14 xl:left-40 xxl:left-72 w-[7rem] sm:w-[9.5625rem] md:w-[11rem]"
	>
		<!-- Galleta arriba izquierda pequeña -->
		<img
			class="rotate-[6.42deg]"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
			alt="Cookie photo"
		/>
	</div>
	<div
		class="absolute top-[1.7rem] sm:top-[1rem] md:top-0 left-[2rem] sm:left-0 md:left-5 lg:left-28 xl:left-56 xxl:left-[22rem] w-[9.8125rem] sm:w-[13.375rem] md:w-[16.875rem]"
	>
		<!-- Galleta arriba izquierda grande -->
		<img
			class="-rotate-[9.39deg]"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
			alt="Cookie photo"
		/>
	</div>
	<div
		class="absolute -bottom-6 sm:-bottom-10 md:-bottom-8 lg:-bottom-11 xxl:-bottom-7 right-20 sm:right-28 md:right-10 lg:right-32 xl:right-24 xxl:right-52 w-[6.2rem] sm:w-[8.5rem] md:w-[8rem] lg:w-[10.8125rem] xxl:w-[120px]"
	>
		<!-- Galleta abajo derecha pequeña -->
		<img
			class="rotate-[11.36deg]"
			src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
			alt="Cookie photo"
		/>
	</div>
</div>
