import { Component, ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'app-google-tag-manager',
	template: '',
})
export class GoogleTagManagerComponent {
	gtmId = environment.googleTagManagerId;

	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: Object,
		private readonly renderer: Renderer2,
		private readonly el: ElementRef,
	) {
		// BROWSER
		if (isPlatformBrowser(this.platformId)) {
			const script = this.renderer.createElement(
				'script',
			) as HTMLScriptElement;
			script.src = `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}`;
			script.async = true;
			this.renderer.appendChild(this.el.nativeElement, script);

			const script2 = this.renderer.createElement(
				'script',
			) as HTMLScriptElement;
			const scriptBody = this.renderer.createText(`
				window.dataLayer = window.dataLayer || [];
				function gtag() {
				dataLayer.push(arguments);
				}
				gtag('js', new Date());

				gtag('config', '${this.gtmId}');
			`);
			this.renderer.appendChild(script2, scriptBody);
			this.renderer.appendChild(this.el.nativeElement, script2);

			const noscript = this.renderer.createElement(
				'noscript',
			) as HTMLScriptElement;

			const iframe = this.renderer.createElement(
				'iframe',
			) as HTMLIFrameElement;
			iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-P6JLMSF';
			iframe.height="0";
			iframe.width="0";
			iframe.style.display = 'none';
			iframe.style.visibility = 'hidden';
			this.renderer.appendChild(noscript,iframe)
			this.renderer.appendChild(this.el.nativeElement,noscript)
		}
	}
}
