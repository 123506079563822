import { Component } from '@angular/core';

@Component({
  selector: 'app-header-tws',
  templateUrl: './header-tws.component.html',
  styleUrl: './header-tws.component.sass'
})
export class HeaderTwsComponent {

}
