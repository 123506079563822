import { InjectionToken, makeStateKey } from '@angular/core';
import { KPI } from './app/interfaces/kpi';

export const KPIS_TOKEN = new InjectionToken<string>('kpiToken');
export const KPIS_VALUE = makeStateKey<KPI>('kpiVal');

export const PROCESSES_TOKEN = new InjectionToken<string>('processesToken');
export const PROCESSES_VALUE = makeStateKey<any[]>('processesVal');

export const POSTS_TOKEN = new InjectionToken<string>('postsToken');
export const POSTS_VALUE = makeStateKey<any[]>('postsVal');

export const POSTCATEGORIES_TOKEN = new InjectionToken<string>('categoriesToken');
export const POSTCATEGORIES_VALUE = makeStateKey<any[]>('categoriesVal');

export const POST_BY_ID_TOKEN = new InjectionToken<string>('postByIdToken');
export const POST_BY_ID_VALUE = makeStateKey<any>('postByIdVal');


export const PROCESSES_COUNTRY_ISO_TOKEN = new InjectionToken<any>('processesIsoToken');
export const PROCESSES_COUNTRY_ISO_VALUE = makeStateKey<any>('processesIsoVal');

export const COUNTRIES_TOKEN = new InjectionToken<any>('countriesToken');
export const COUNTRIES_VALUE = makeStateKey<any>('countriesVal');
