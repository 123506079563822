<section class="main-container bg-MoradoClaro">
	<div class="blog-container">
		<div
			class="header-4 text-teal-900 text-[50px] font-extrabold font-['Montserrat']"
		>
			BLOG
		</div>
		<div class="middle-container">
			<div class="cards-container">
				<a [href]="posts[0].postUrl ? '/post/' + posts[0].postUrl : ''">
					<div class="card primary-card">
						<img
							class="object-cover image-primary rounded-[15px]"
							src="{{ posts[0].postImageUrl }}"
						/>
						<div class="primary-text-field">
							<span
								class="primary-text sm:header-3 header-5 text-teal-900 font-['Montserrat']"
							>
								{{ posts[0].postTitle }}<br /><br />
							</span>
							<span
								class="sub-text text-teal-900 text-xl font-medium font-['Poppins'] line-clamp-4"
							>
								{{ posts[0].postMetadescription }}
							</span>
						</div>
					</div>
				</a>
				<a [href]="posts[1].postUrl ? '/post/' + posts[1].postUrl : ''">
					<div class="card primary-card">
						<img
							class="object-cover image-primary rounded-[15px]"
							src="{{ posts[1].postImageUrl }}"
						/>
						<div class="primary-text-field">
							<span
								class="truncate-text primary-text sm:header-3 header-5 text-teal-900 font-['Montserrat']"
							>
								{{ posts[1].postTitle }}<br /><br />
							</span>
							<span
								class="sub-text text-teal-900 text-xl font-medium font-['Poppins'] line-clamp-4"
							>
								{{ posts[1].postMetadescription }}
							</span>
						</div>
					</div>
				</a>
				<a [href]="posts[2].postUrl ? '/post/' + posts[2].postUrl : ''">
					<div class="card secondary-card">
						<img
							class="object-cover image-secondary rounded-[15px]"
							src="{{ posts[2].postImageUrl }}"
						/>
						<div
							class="secondary-text-field sm:header-3 header-5 text-teal-900 font-['Montserrat']"
						>
							{{ posts[2].postTitle }}
						</div>
					</div>
				</a>
				<a [href]="posts[3].postUrl ? '/post/' + posts[3].postUrl : ''">
					<div class="card secondary-card">
						<img
							class="object-cover image-secondary rounded-[15px]"
							src="{{ posts[3].postImageUrl }}"
						/>
						<div
							class="secondary-text-field sm:header-3 header-5 text-teal-900 font-['Montserrat']"
						>
							{{ posts[3].postTitle }}
						</div>
					</div>
				</a>
				<a [href]="posts[4].postUrl ? '/post/' + posts[4].postUrl : ''">
					<div class="card secondary-card">
						<img
							class="object-cover image-secondary rounded-[15px]"
							src="{{ posts[4].postImageUrl }}"
						/>
						<div
							class="secondary-text-field sm:header-3 header-5 text-teal-900 font-['Montserrat']"
						>
							{{ posts[4].postTitle }}
						</div>
					</div>
				</a>
				<a [href]="posts[5].postUrl ? '/post/' + posts[5].postUrl : ''">
					<div class="card secondary-card">
						<img
							class="object-cover image-secondary rounded-[15px]"
							src="{{ posts[5].postImageUrl }}"
						/>
						<div
							class="truncate-last-card secondary-text-field sm:header-3 header-5 text-teal-900 font-['Montserrat']"
						>
							{{ posts[5].postTitle }}
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</section>
