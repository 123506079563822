<div
	class="ktis-container w-full bg-Verde text-VerdeOscuro flex xs:flex-col sm:flex-col md:flex-row md:columns-3 md:py-5 lg:py-7 xl:py-9 xxl:py-11">
	<div
		class="users flex flex-col items-center justify-center relative md:w-1/3 xs:pb-24 xs:pt-12 sm:pb-24 sm:pt-8 md:p-0">
		<div class="logo-container user-logo flex justify-center items-center">
			<img class="user-icon"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fusers.svg?alt=media">
		</div>
		<div class="flex flex-col items-center justify-center">
			<h1 [countUp]="num_users" [duration]="animationDuration" class="montserrat header-3-eb sm:header-1-eb md:header-3-eb lg:header-2-eb xl:header-0-eb"></h1>
			<p class="poppins body-3-r sm:body-1-r md:body-5-m lg:body-3-m xl:body-2-m">{{ "shared.ktisMessage.usersMessage" | translate }}</p>
		</div>
	</div>
	<hr class="bg-VerdeOscuro md:hidden">
	<div class="bg-VerdeOscuro vertical-line xs:hidden sm:hidden md:block"></div>
	<div
		class="jobs flex flex-col items-center justify-center relative md:w-1/3 xs:pb-24 xs:pt-8 sm:pb-24 sm:pt-8 md:p-0">
		<div class="logo-container jobs-logo flex justify-center items-center">
			<img class="jobs-icon"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fjobs.svg?alt=media">
		</div>
		<div class="flex flex-col items-center justify-center">
			<h1 [countUp]="num_jobs" [duration]="animationDuration" class="montserrat header-3-eb sm:header-1-eb md:header-3-eb lg:header-2-eb xl:header-0-eb"></h1>
			<p class="poppins body-3-r sm:body-1-r md:body-5-m lg:body-3-m xl:body-2-m">{{ "shared.ktisMessage.jobsMessage" | translate }}</p>
		</div>
	</div>
	<hr class="bg-VerdeOscuro md:hidden">
	<div class="bg-VerdeOscuro vertical-line xs:hidden sm:hidden md:block"></div>
	<div
		class="evals flex flex-col items-center justify-center relative md:w-1/3 xs:pb-10 xs:pt-8 sm:pb-12 sm:pt-8 md:p-0">
		<div class="logo-container evals-logo flex justify-center items-center">
			<img class="evals-icon"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fevals.svg?alt=media">
		</div>
		<div class="flex flex-col items-center justify-center">
			<h1 [countUp]="num_evals" [duration]="animationDuration" class="montserrat header-3-eb sm:header-1-eb md:header-3-eb lg:header-2-eb xl:header-0-eb"></h1>
			<p class="poppins body-3-r sm:body-1-r md:body-5-m lg:body-3-m xl:body-2-m">{{ "shared.ktisMessage.evalsMessage" | translate }}</p>
		</div>
	</div>
</div>
