<button (click)="clickEvent()"
		class="rounded-3xl montserrat button-2 py-[0.6875rem] px-10"
		[class.button-2]="small == false"
		[class.button-3]="small == true && (typeEnum.MAIN_VERDE == buttonType || typeEnum.MAIN_MORADO == buttonType)"
		[class.header-5]="small == true && (typeEnum.MAIN_BLANCO == buttonType || typeEnum.BORDE_BLANCO == buttonType)"
		[ngClass]="style"
		[id]="idForButton"
>
  {{text}}
</button>

