import { Component, Input } from '@angular/core';
import { BlogPost, Category } from '../../../interfaces/blog';

@Component({
	selector: 'app-blog-card',
	templateUrl: './blog-card.component.html',
	styleUrl: './blog-card.component.sass',
})
export class BlogCardComponent {
	@Input() post: BlogPost = {
		postId: '',
		category: {
			categoryId: '',
			categoryTitle: '',
		} as Category ,
		postTitle: '',
		postMetadescription: '',
		postUrl: '',
		postImageUrl: '',
		postText: '',
		postCreatedAt: new Date(),
	};

	onCardImageError(event: any) {
		event.target.src = "https://placehold.co/600x400"
	}
}
