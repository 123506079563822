<a [href]="'/post/' + this.post.postUrl">
	<div
		class="flex flex-col w-full sm:flex-row md:flex-col lg:flex-row xl:flex-col p-4 bg-white rounded-2xl gap-6 h-full"
	>
		<img
			[src]="this.post.postImageUrl"
			class="rounded-lg w-full object-cover
				xl:min-h-[18.75rem] lg:min-h-full md:min-h-[15rem] sm:min-h-full min-h-[15rem]
				xl:max-h-[18.75rem] lg:max-h-full md:max-h-[15rem] sm:max-h-full max-h-[15rem]
				max-w-full sm:max-w-[50%] md:max-w-full lg:max-w-[50%] xl:max-w-full"
			(error)="onCardImageError($event)"
		/>
		<div class="flex flex-col gap-6 xl:justify-between lg:justify-center md:justify-between sm:justify-center justify-between">
			<span class="font-bold text-2xl text-VerdeOscuro">
				{{this.post.postTitle}}
			</span>
			<span class="font-normal text-base text-VerdeOscuro line-clamp-4">{{
				this.post.postMetadescription
			}}</span>
			<span class="montserrat text-VerdeOscuro text-right text-[1rem] font-bold leading-5"> {{ this.post.postCreatedAt | date: 'dd MMM yyyy'}}</span>
		</div>
	</div>
</a>
