<section class="main-container">
	<div
		class="card overflow-hidden"
		[ngClass]="
			theme == CardsTheme.DARK
				? 'card-background-dark'
				: 'card-background-light'
		"
	>
		<div class="text-container">
			<p
				[countUp]="num_users"
				[duration]="animationDuration"
				class="cifra montserrat header-2-eb sm:header-1-eb md:header-3-eb lg:header-2-eb xl:header-0-eb"
				[ngClass]="
					theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
				"
			></p>
			<div class="flex subtext">
				<img
					[src]="
						theme == CardsTheme.LIGHT ? usuariosDark : usuariosLight
					"
				/>
				<p
					class="poppins body-3-r sm:body-2-r md:body-5-r lg:body-3-r xl:body-2-r title"
					[ngClass]="
						theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
					"
				>
					{{ "shared.ktisMessage.usersMessage" | translate }}
				</p>
			</div>
		</div>
	</div>
	<div
		class="card overflow-hidden"
		[ngClass]="
			theme == CardsTheme.DARK
				? 'card-background-dark'
				: 'card-background-light'
		"
	>
		<div class="text-container">
			<p
				[countUp]="num_jobs"
				[duration]="animationDuration"
				class="cifra montserrat header-2-eb sm:header-1-eb md:header-3-eb lg:header-2-eb xl:header-0-eb"
				[ngClass]="
					theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
				"
			></p>
			<div class="flex subtext">
				<img
					[src]="
						theme == CardsTheme.LIGHT ? trabajosDark : trabajosLight
					"
				/>
				<p
					class="poppins body-3-r sm:body-2-r md:body-5-r lg:body-3-r xl:body-2-r title"
					[ngClass]="
						theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
					"
				>
					{{ "shared.ktisMessage.jobsMessage" | translate }}
				</p>
			</div>
		</div>
	</div>
	<div
		class="card overflow-hidden"
		[ngClass]="
			theme == CardsTheme.DARK
				? 'card-background-dark'
				: 'card-background-light'
		"
	>
		<div class="text-container">
			<p
				[countUp]="num_evals"
				[duration]="animationDuration"
				class="cifra montserrat header-2-eb sm:header-1-eb md:header-3-eb lg:header-2-eb xl:header-0-eb"
				[ngClass]="
					theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
				"
			></p>
			<div class="flex subtext">
				<img
					[src]="
						theme == CardsTheme.LIGHT
							? evaluacionesDark
							: evaluacionesLight
					"
				/>
				<p
					class="poppins body-3-r sm:body-2-r md:body-5-r lg:body-3-r xl:body-2-r title"
					[ngClass]="
						theme == CardsTheme.LIGHT ? 'dark-text' : 'light-text'
					"
				>
					{{ "shared.ktisMessage.evalsMessage" | translate }}
				</p>
			</div>
		</div>
	</div>
</section>
