<div
	class="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 z-[1000] cookies-container"
>
	<div
		class="fixed inset-x-0 bottom-0 z-50 flex flex-col items-center justify-center bg-VerdeOscuro text-white p-4 sm:p-6 rounded-t-3xl"
	>
		<div
			class="absolute -top-[13px] -left-[19px] w-[86px] sm:w-[114px] md:w-[72px] xxl:w-[99px]"
		>
			<!-- Arriba izquierda pequeña -->
			<img
				class="rotate-12 md:-rotate-3"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
				alt="Cookie photo"
			/>
		</div>
		<div
			class="absolute top-0 md:-top-12 left-0 xxl:left-3 w-[121px] sm:w-[160px] md:w-[92px] xxl:w-[130px]"
		>
			<!-- Arriba izquierda grande -->
			<img
				class="-rotate-12 md:rotate-3"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
				alt="Cookie photo"
			/>
		</div>
		<div
			class="absolute top-[133px] right-[10px] w-[65px] sm:w-[90px] md:w-[72px] md:-bottom-5 md:top-auto xxl:w-[79px]"
		>
			<!-- Abajo derecha -->
			<img
				class="rotate-12"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Fcookie.png?alt=media"
				alt="Cookie photo"
			/>
		</div>
		<div
			class="grid grid-cols-1 md:grid-cols-4 justify-items-center items-center"
		>
			<img
				class="w-[176px]"
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Ficons%2Ftws-cookies-log.svg?alt=media"
				alt="Logo The Wise Seeker"
			/>
			<div
				class="text-center md:text-start mt-4 mb-10 sm:mx-5 md:mx-10 md:col-span-2"
			>
				<p class="body-5-m poppins mb-4">
					{{ 'shared.modalCookiesConsent.title' | translate }}
				</p>
				<p class="body-6-m poppins">
					{{ 'shared.modalCookiesConsent.message' | translate }}
					<br />
					{{ 'shared.modalCookiesConsent.moreInfo' | translate }}
					<a
					class="underline cursor-pointer"
					(click)="this.navigateToCookiesPage.emit()"
					>
					{{ 'shared.modalCookiesConsent.cookiesPolicy' | translate }}
					</a
					>
				</p>
			</div>
			<div class="flex flex-col gap-y-4 mb-4 buttons-container">
				<app-general-button
					[addedClasses]="'w-full'"
					[text]="('shared.modalCookiesConsent.buttonAcceptAll' | translate) | uppercase"
					[buttonType]="buttonType.BORDE_BLANCO"
					[small]="true"
					(buttonClick)="this.acceptAllCookies.emit()"
				></app-general-button>
				<app-general-button
					[addedClasses]="'w-full'"
					[text]="('shared.modalCookiesConsent.buttonAcceptMandatory' | translate) | uppercase"
					[buttonType]="buttonType.BORDE_BLANCO"
					[small]="true"
					(buttonClick)="this.acceptMandatoryCookies.emit()"
				></app-general-button>
			</div>
		</div>
	</div>
</div>
