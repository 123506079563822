<div class="flex justify-start items-center flex-col h-full" [ngClass]="{
			'card-one': cardType == typeEnum.MORADO_CLARO,
			'card-two': cardType == typeEnum.CLARO,
			'card-three': cardType == typeEnum.OSCURO
		}">
	@if (thereIsImage == true) {
	<div class="card-header flex justify-center items-center montserrat text-center" [ngClass]="{
			'lg:header-3 md:header-4 header-3' : cardType == typeEnum.CLARO || cardType == typeEnum.OSCURO,
			'text-[#F2F2F2]': cardType == typeEnum.OSCURO || cardType == typeEnum.MORADO_CLARO,
			'text-VerdeOscuro': cardType == typeEnum.CLARO,
			'bg-AzulOscuro': cardType == typeEnum.MORADO_CLARO,
			'bg-MoradoClaro': cardType == typeEnum.CLARO,
			'bg-VerdeOscuro': cardType == typeEnum.OSCURO
		}">
		<img class="img-header" src={{headerUrl}}>
	</div>
	}
	@else {
	<div class="card-header flex justify-center items-center montserrat text-center" [ngClass]="{
				'lg:header-3 md:header-4 header-3' : cardType == typeEnum.CLARO || cardType == typeEnum.OSCURO,
				'text-[#F2F2F2]': cardType == typeEnum.OSCURO || cardType == typeEnum.MORADO_CLARO,
				'text-VerdeOscuro': cardType == typeEnum.CLARO,
				'bg-AzulOscuro': cardType == typeEnum.MORADO_CLARO,
				'bg-MoradoClaro': cardType == typeEnum.CLARO,
				'bg-VerdeOscuro': cardType == typeEnum.OSCURO
			}">
		{{header}}
	</div>
	}
	<div class="card-title flex justify-center items-center montserrat text-center my-5" [ngClass]="{
			'text-VerdeNeon': cardType == typeEnum.MORADO_CLARO || cardType == typeEnum.OSCURO,
			'text-VerdeOscuro': cardType == typeEnum.CLARO,
			'lg:header-6-eb md:header-7-eb header-6-eb': cardType == typeEnum.MORADO_CLARO || cardType == typeEnum.OSCURO,
			'lg:header-3 md:header-4 header-3': cardType == typeEnum.CLARO
		}">
		{{title}}
	</div>
	<div class="card-text flex justify-center items-center poppins text-center" [ngClass]="{
			'text-[#F2F2F2]': cardType == typeEnum.MORADO_CLARO || cardType == typeEnum.OSCURO,
			'text-VerdeOscuro': cardType == typeEnum.CLARO,
			'xxl:lg:body-4-m xl:body-4-m lg:body-4-m md:body-5-m body-4-m': cardType == typeEnum.MORADO_CLARO || cardType == typeEnum.OSCURO,
			'xxl:body-4-m body-5-m': cardType == typeEnum.CLARO
		}">
		{{text}}
	</div>
</div>
