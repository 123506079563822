<section
	class="flex flex-row items-center justify-center text-VerdeOscuro w-full h-full font-bold text-[1.25] montserrat"
>
	@if (numberOfPages > 1) {
		<section
			class="flex flex-row items-center w-[35.4rem] h-[2.75rem] mb-[5.44rem] justify-center md:mb-[4.56rem] sm:mb-[4.56rem] xs:mb-[3.94rem]"
			*ngIf="numberOfPages > 1"
		>
			<img
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flight%2Fchevrons%2Fchevron-left.svg?alt=media"
				width="28px"
				data-cy="chevron-left"
				[class.chevron-disabled]="disablePrevious"
				(click)="previousPage()"
				class="mr-[2.94rem] cursor-pointer hidden sm:block"
			/>
			@if (numberOfPages > 7) {
				<div class="flex items-center gap-5">
					@for (
						pageNumber of getVisiblePageNumbers();
						track pageNumber
					) {
						<div
							class="w-[2.75rem] h-[2.75rem] flex justify-center items-center rounded-full pagination-button"
							[class.cursor-pointer]="pageNumber != '...'"
							[ngClass]="{
								'hover:border-VerdeOscuro hover:border-[0.188rem] hover:shadow-[rgba(0,71,81,1)_0px_0px_0.625rem_0.063rem] hover:bg-[#FFFFFF40]':
									isPageNumberClickable(pageNumber),
								'border-VerdeOscuro border-[0.188rem]':
									pageSelected === pageNumber
							}"
							(click)="
								isPageNumberClickable(pageNumber) &&
									selectPage(pageNumber)
							"
						>
							{{ pageNumber }}
						</div>
					}
				</div>
			}
			@if (numberOfPages <= 7) {
				<div class="flex items-center gap-5">
					<div
						*ngFor="let _ of counterPages(); let i = index"
						class="w-[2.75rem] h-[2.75rem] flex justify-center items-center rounded-full pagination-button hover:border-VerdeOscuro hover:border-[3px] hover:shadow-[rgba(0,71,81,1)_0px_0px_10px_1px] hover:bg-[#FFFFFF40]"
						[ngClass]="{
							'border-VerdeOscuro border-[3px]':
								pageSelected == i,
							'cursor-pointer': true
						}"
						(click)="selectPage(i)"
					>
						{{ i + 1 }}
					</div>
				</div>
			}
			<img
				src="https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/assets%2Fimg%2Flight%2Fchevrons%2Fchevron-right.svg?alt=media"
				width="28px"
				data-cy="chevron-right"
				[class.chevron-disabled]="disableNext"
				class="ml-[2.94rem] cursor-pointer hidden sm:block"
				(click)="nextPage()"
			/>
		</section>
	}
</section>
