import { Component, Input } from '@angular/core';

export enum CarrouselType {
	VERDE_VERDE = 1,
	VERDE_MORADO = 2,
	MORADO_MORADO = 3,
	NEON_VERDE = 4
}

import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-carrousel-empresas',
	templateUrl: './carrousel-empresas.component.html',
	styleUrl: './carrousel-empresas.component.sass'
})
export class CarrouselEmpresasComponent {
	img_urls: string[] = [
		"https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Flogos-empresas%2Flogo-prinzipal-partners.png?alt=media",
		"https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Flogos-empresas%2Flogo-telefonica.png?alt=media",
		"https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Flogos-empresas%2Flogo-mahou.png?alt=media",
		"https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Flogos-empresas%2Flogo-nunsys.png?alt=media",
		"https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Flogos-empresas%2Flogo-talentoo.png?alt=media",
		"https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Flogos-empresas%2Flogo-sabadell.png?alt=media",
		"https://firebasestorage.googleapis.com/v0/b/the-wise-seeker-production.appspot.com/o/web-public%2Flogos-empresas%2Flogo-laliga.png?alt=media"
	];

	@Input() upper_text: string = "TEXT FOR THE UPPER CARROUSEL";
	@Input() lower_text: string[] = ["ROLE NAME 1", "ROLE NAME 2", "ROLE NAME 3", "ROLE NAME 4", "ROLE NAME 5", "ROLE NAME 6"];
	@Input() carrouselType: CarrouselType = CarrouselType.VERDE_VERDE;
	@Input() isEnterprise: boolean = false;

	//Este input es para comprobar que el carrusel se encuentra en la pagina de blog.
	@Input() isBlog: boolean = false;

	typeEnum = CarrouselType;

	randomizedText = "";

	constructor(private translate:TranslateService){}

	ngOnInit() {
		this.randomizedText = "";
        this.generarTextoAleatorio();
    }

	ngOnChanges(){
		this.randomizedText = "";
		this.generarTextoAleatorio();
	}

	generarTextoAleatorio() {
		const textoAleatorio = [...this.lower_text];

		while (this.randomizedText.split(' · ').length - 1 < 5 && textoAleatorio.length > 0) {
			const index = Math.floor(Math.random() * textoAleatorio.length);
			this.randomizedText += (this.randomizedText === '' ? '' : ' · ') + textoAleatorio.splice(index, 1)[0];
		}

		this.randomizedText += ' ·';
	}

}
